const QUERIES = {
  USERS_LIST: 'users-list',
  SPORTS_TYPE_LIST: 'sports-type-list',
  BET_TYPE_LIST: 'bet-type-list',
  BET_LIST: 'bet-list',
  PREDICTION_FOOTBALL_LIST: 'prediction-football-list',
  CORNER_PREDICTION_LIST: 'corner-prediction-list',
  CORNER_PREDICTION_YESTERDAY_LIST: 'corner-prediction-list-yesterday',
  CORNER_PREDICTION_UNDER_8_LIST: 'corner-prediction-list-under-8',
  CORNER_PREDICTION_UNDER_10_LIST: 'corner-prediction-list-under-10',
  CORNER_PREDICTION_UNDER_6_LIST: 'corner-prediction-list-under-6',
  CORNER_PREDICTION_OVER_6_LIST: 'corner-prediction-list-over-6',
  CORNER_PREDICTION_OVER_8_LIST: 'corner-prediction-list-over-8',
  CORNER_PREDICTION_OVER_10_LIST: 'corner-prediction-list-over-10',
  CORNER_PREDICTION_OVER_12_LIST: 'corner-prediction-list-over-12',
  GOAL_PREDICTION_LIST: 'goal-prediction-list',
  GOAL_PREDICTION_UNDER_4_LIST: 'goal-prediction-list-under-4',
  GOAL_PREDICTION_UNDER_6_LIST: 'goal-prediction-list-under-6',
  GOAL_PREDICTION_UNDER_2_LIST: 'goal-prediction-list-under-2',
  GOAL_PREDICTION_OVER_2_LIST: 'goal-prediction-list-over-2',
  GOAL_PREDICTION_OVER_3_LIST: 'goal-prediction-list-over-3',
  GOAL_PREDICTION_OVER_6_LIST: 'goal-prediction-list-over-6',
  GOAL_PREDICTION_OVER_12_LIST: 'goal-prediction-list-over-12',
  GOAL_PREDICTION_YESTERDAY_LIST: 'goal-prediction-list-yesterday',
  ACCURACY_AWAY_TEAM: 'accuracy-away-team',
  ACCURACY_HOME_TEAM: 'accuracy-home-team',
  ACCURACY_TEAM: 'accuracy-team',
  ACCURACY_MODEL: 'accuracy-model',
  ACCURACY_CORRECT_MODEL: 'accuracy-model_correct',
  UNDER_25: 'prediction-under25-list',
  OVER_25: 'prediction-over25-list',
  CORNER_UPCOMING_PREDICTION_LIST: 'CORNER_UPCOMING_PREDICTION_LIST',
  GOAL_UPCOMING_PREDICTION_LIST: 'GOAL_UPCOMING_PREDICTION_LIST',
  OVER_15: 'prediction-over15-list',
  SEARCH_PREDICTION: 'prediction-SEARCH-list',
  BANKROLL_LIST: 'bankroll-list',
}

export {QUERIES}
