import {useIntl} from 'react-intl'
import {MenuItem} from './MenuItem'
import {MenuInnerWithSub} from './MenuInnerWithSub'

export function MenuInner() {
  const intl = useIntl()
  return (
    <>
      <MenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD'})} to='/dashboard' />

      <MenuInnerWithSub title='Apps' to='/apps' menuPlacement='bottom-start' menuTrigger='click'>
        {/* PAGES */}
        {/*<MenuInnerWithSub*/}
        {/*  title='Chat'*/}
        {/*  to='/apps/chat'*/}
        {/*  icon='message-text-2'*/}
        {/*  hasArrow={true}*/}
        {/*  menuPlacement='right-start'*/}
        {/*  menuTrigger={`{default:'click', lg: 'hover'}`}*/}
        {/*>*/}
        {/*  <MenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />*/}
        {/*  <MenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />*/}
        {/*  <MenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />*/}
        {/*</MenuInnerWithSub>*/}
        <MenuItem icon='abstract-28' to='/user-management/users' title='User management' />
      </MenuInnerWithSub>
    </>
  )
}
