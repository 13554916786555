export const formatDate = (date: Date | string | null) => {
  if (!date) {
    return 'N/A'
  }

  const parsedDate = new Date(date)
  if (isNaN(parsedDate.getTime())) {
    return 'Invalid Date'
  }

  const year = parsedDate.getFullYear()
  const month = String(parsedDate.getMonth() + 1).padStart(2, '0')
  const day = String(parsedDate.getDate()).padStart(2, '0')
  const hour = String(parsedDate.getHours()).padStart(2, '0')
  const minutes = String(parsedDate.getMinutes()).padStart(2, '0')

  return `${day}/${month}/${year} - ${hour}h:${minutes}m`
}
