export const API_URL = process.env.REACT_APP_API_URL
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`
export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/me`
export const GET_USER_BY_ID_URL = `${API_URL}/users`
export const GET_USERS_URL = `${API_URL}/users`
export const AUTH_LOCAL_STORAGE_KEY = 'seb-auth'
export const MAX_REFRESH_RETRIES = 2
export const REACT_LOGIN_URL = '/auth'
export const GET_USER_BY_ID = `${API_URL}/users/user`
export const CREATE_USER_URL = `${API_URL}/users/user`
export const DELETE_USER_URL = `${API_URL}/users/user`
export const UPDATE_USER_URL = `${API_URL}/users/user`
export const LOGIN_URL = `${API_URL}/auth/signin`
export const REGISTER_URL = `${API_URL}/auth/signup`
export const SPORTS_TYPE_URL = `${API_URL}/sports-type`
export const BET_TYPE_URL = `${API_URL}/bets-type`
export const BET_URL = `${API_URL}/bet`
export const PREDICTION_FOOTBALL_URL = `${API_URL}/prediction-corners`
export const CORNER_PREDICTION_URL = `${API_URL}/prediction-corners`
export const CORNER_UPCOMING_PREDICTION_URL = `${API_URL}/corners/upcoming-prediction`
export const CORNER_YESTERDAY_PREDICTION_URL = `${API_URL}/corners/yesterday-prediction`
export const PREDICTION_FOOTBALL_OVER_15 = `${API_URL}/prediction-corners/over-15-goals`
export const PREDICTION_FOOTBALL_OVER_25 = `${API_URL}/prediction-corners/over-25-goals`
export const PREDICTION_FOOTBALL_UNDER_25 = `${API_URL}/prediction-corners/under-25-goals`
export const GOAL_PREDICTION_URL = `${API_URL}/prediction-goals`
export const PREDICTION_GOAL_OVER_UPCOMING = `${API_URL}/goals/upcoming-prediction`
export const PREDICTION_GOAL_YESTERDAY = `${API_URL}/goals/yesterday-prediction`
export const PREDICTION_GOAL_OVER_15 = `${API_URL}/goals/over-15-goals`
export const PREDICTION_GOAL_UNDER_25 = `${API_URL}/goals/under-25-goals`
export const BANKROLL_URL = `${API_URL}/bankrolls`
export const SEARCH_BANKROLL_URL = `${API_URL}/search/bankroll`
export const SEARCH_SPORTS_TYPE_URL = `${API_URL}/search/sports-type`
export const SEARCH_BETS_TYPE_URL = `${API_URL}/search/bets-type`
export const SEARCH_STATUS = `${API_URL}/search/status`
export const ACCURACY_HOME_TEAM = `${API_URL}/accuracy/home-team`
export const ACCURACY_AWAY_TEAM = `${API_URL}/accuracy/away-team`
export const ACCURACY_TEAM = `${API_URL}/accuracy/team`
export const ACCURACY_MODEL_CORRECT = `${API_URL}/accuracy/model-correct`
export const ACCURACY_MODEL_ACCURACY = `${API_URL}/accuracy/model-accuracy`
export const ACCURACY_DELETE = `${API_URL}/accuracy/model-accuracy`
