import {AuthModel} from './_models'
import axios, {AxiosInstance, AxiosRequestConfig} from 'axios'
import {API_URL, AUTH_LOCAL_STORAGE_KEY, MAX_REFRESH_RETRIES, REACT_LOGIN_URL} from '../../../utils'

const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

async function refreshToken(retryCount: number = 0): Promise<AuthModel | undefined> {
  try {
    const auth = getAuth()
    if (!auth) {
      console.error('Error refreshing token: Auth data not found')
      return
    }

    const refreshToken = auth.refresh_token
    if (!refreshToken) {
      console.error('Error refreshing token: Refresh token not found')
      removeAuth()
      return
    }
    const response = await axios.get(API_URL + '/auth/refresh', {
      headers: {
        Refresh: `Bearer ${refreshToken}`,
      },
    })

    const newAuth = response.data as AuthModel | undefined

    if (newAuth) {
      setAuth(newAuth)
      return newAuth
    } else {
      console.error('Error refreshing token: Refresh response does not contain auth data')
    }
  } catch (error) {
    console.error('Error refreshing token:', error)

    if (retryCount < MAX_REFRESH_RETRIES) {
      console.log('Retrying token refresh...')
      return Promise.reject(error)
    } else {
      console.log('Max retry count reached. Logging out user...')
      removeAuth()
      // Rediriger vers la page de login
      window.location.href = REACT_LOGIN_URL
    }
  }
}

export function setupAxios(axiosInstance: AxiosInstance) {
  axiosInstance.interceptors.request.use(
    async (config: AxiosRequestConfig) => {
      const auth = getAuth()
      if (auth && auth.access_token) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${auth.access_token}`,
          Accept: 'application/json', // Ajout de l'en-tête Accept ici
        }
      }
      return config
    },
    (err: any) => Promise.reject(err)
  )

  let isRefreshing = false
  let refreshSubscribers: ((token: string) => void)[] = []

  const subscribeTokenRefresh = (cb: (token: string) => void) => {
    refreshSubscribers.push(cb)
  }

  const onTokenRefreshed = (token: string) => {
    refreshSubscribers.forEach((cb) => cb(token))
  }

  axiosInstance.interceptors.response.use(
    (response: any) => response,
    async (error: any) => {
      const originalRequest = error.config
      if (error.response.status === 401 && !originalRequest._retry) {
        if (!isRefreshing) {
          isRefreshing = true
          try {
            const auth = getAuth()
            if (!auth) {
              return Promise.reject(error)
            }

            const newAuth = await refreshToken()
            isRefreshing = false
            if (newAuth) {
              setAuth(newAuth)
              onTokenRefreshed(newAuth.access_token)
              originalRequest.headers.Authorization = `Bearer ${newAuth.access_token}`
              originalRequest._retry = true
              return axiosInstance(originalRequest)
            } else {
              console.error('Error refreshing token: Refresh response does not contain auth data')
              // Rediriger vers la page de login
              window.location.href = REACT_LOGIN_URL
              return Promise.reject(error)
            }
          } catch (error) {
            isRefreshing = false
            console.error('Error refreshing token:', error)
            if (refreshSubscribers.length < MAX_REFRESH_RETRIES) {
              console.log('Retrying token refresh...')
              return Promise.reject(error)
            } else {
              console.log('Max retry count reached. Logging out user...')
              // Rediriger vers la page de login
              removeAuth()
              window.location.href = REACT_LOGIN_URL
            }
          }
        } else {
          return new Promise((resolve) => {
            subscribeTokenRefresh((token: string) => {
              originalRequest.headers.Authorization = `Bearer ${token}`
              originalRequest._retry = true
              resolve(axiosInstance(originalRequest))
            })
          })
        }
      }
      return Promise.reject(error)
    }
  )
}

export {getAuth, setAuth, removeAuth}
