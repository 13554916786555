/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {SidebarMenuItem} from './SidebarMenuItem'
import {useAuth} from '../../../../../app/modules/auth'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const currentUser = useAuth().currentUser
  const userRole = currentUser ? currentUser.role : ''

  const userRolesAllowed = ['ADMIN', 'SUPERADMIN'] // Rôles d'utilisateur autorisés à voir la partie "User management"
  const shouldShowUserManagement = userRole && userRolesAllowed.includes(userRole)

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
        <SidebarMenuItemWithSub
            title='Corner Prediction'
            to='/app/corner-prediction'
            icon='element-10'
            fontIcon='soccer-ball'
        >
            <SidebarMenuItem
                to='/app/corner-prediction/all'
                title={intl.formatMessage({id: 'MENU.PREDICTION'})}
                hasBullet={true}
            />
            <SidebarMenuItem
                to='/app/corner-prediction/upcoming-prediction'
                title={intl.formatMessage({id: 'MENU.UPCOMING-PREDICTION'})}
                hasBullet={true}
            />
            <SidebarMenuItem
                to='/app/corner-prediction/yesterday'
                title={intl.formatMessage({id: 'MENU.YESTERDAY'})}
                hasBullet={true}
            />
        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub
            title='Goal Prediction'
            to='/app/goal-prediction'
            icon='element-10'
            fontIcon='soccer-ball'
        >
            <SidebarMenuItem
                to='/app/goal-prediction/all'
                title={intl.formatMessage({id: 'MENU.PREDICTION'})}
                hasBullet={true}
            />
            <SidebarMenuItem
                to='/app/goal-prediction/upcoming-prediction'
                title={intl.formatMessage({id: 'MENU.UPCOMING-PREDICTION'})}
                hasBullet={true}
            />
            <SidebarMenuItem
                to='/app/goal-prediction/yesterday'
                title={intl.formatMessage({id: 'MENU.YESTERDAY'})}
                hasBullet={true}
            />
        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub
            title='Accuracy'
            to='/app/accuracy'
            icon='element-10'
            fontIcon='soccer-ball'
        >
            <SidebarMenuItem
                to='/app/accuracy/home-team'
                title={intl.formatMessage({id: 'MENU.HOME-TEAM'})}
                hasBullet={true}
            />
            <SidebarMenuItem
                to='/app/accuracy/away-team'
                title={intl.formatMessage({id: 'MENU.AWAY-TEAM'})}
                hasBullet={true}
            />
            <SidebarMenuItem
                to='/app/accuracy/team'
                title={intl.formatMessage({id: 'MENU.TEAM'})}
                hasBullet={true}
            />
            <SidebarMenuItem
                to='/app/accuracy/model-accuracy'
                title={intl.formatMessage({id: 'MENU.MODEL-ACCURACY'})}
                hasBullet={true}
            />
            <SidebarMenuItem
                to='/app/accuracy/model-correct'
                title={intl.formatMessage({id: 'MENU.MODEL-CORRECT'})}
                hasBullet={true}
            />
        </SidebarMenuItemWithSub>

      <SidebarMenuItem
        to='/sports-type'
        icon='element-11'
        title={intl.formatMessage({id: 'MENU.SPORTS_TYPE'})}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/app/bankrolls'
        icon='element-11'
        title={intl.formatMessage({id: 'MENU.BANKROLLS'})}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/app/bets'
        icon='element-11'
        title={intl.formatMessage({id: 'MENU.BET'})}
        fontIcon='bi-app-indicator'
      />
        <SidebarMenuItem
        to='/app/bet-type'
        icon='element-11'
        title={intl.formatMessage({id: 'MENU.BET_TYPE'})}
        fontIcon='bi-app-indicator'
      />
      {shouldShowUserManagement && (
        <SidebarMenuItem
          to='/user-management/users'
          icon='abstract-28'
          title='User management'
          fontIcon='bi-layers'
        />
      )}
    </>
  )
}

export {SidebarMenuMain}
